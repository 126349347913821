<template>
<div class="panel">
    <div class="panel-heading has-background-grey-lighter has-text-weight-bold">
        <div class="columns is-mobile">
            <div class="level-left has-text-centered" @click="showInventoryPriceCalculator=!showInventoryPriceCalculator">
                &nbsp;&nbsp;&nbsp;&nbsp;
                <i class="fas fa-plus-square has-text-primary" v-if="!showInventoryPriceCalculator && !isPrinting"></i>
                <i class="fas fa-minus-square has-text-danger" v-if="showInventoryPriceCalculator && !isPrinting"></i>
            </div>
            <div class="column is-size-6 is-family-sans-serif has-text-centered" id="#intro-calc-merch-head"> MERCHANDISE PRICE CALCULATOR </div>
            <b-button class="intro-show-button"  id="intro-show-button-icon" @click="forceIntro(introSteps)">
                    <b-icon
                        pack="fas"
                        icon="lightbulb"
                        type="is-warning"                    
                        size="is-small">
                    </b-icon>
                </b-button>
        </div>
    </div>
    <div class="panel-block">
        <div class="control columns custom-boxes-container has-text-weight-bold" 
             v-if="showInventoryPriceCalculator">
            <article class="column box">
                <div class="columns is-mobile">
                    <div class="column is-6">
                        Merchandise Cost: 
                    </div>
                    <div class="column has-text-right">
                        <VeeInput 
                            type="currency" 
                            v-model="merchCost"
                            name="merchCost"
                            :value="merchCost"
                            :countryCurrencyCode="countryCurrencyCode"
                            :editable="trueValue"
                            @input="reCalculate"
                            />
                    </div>
                </div>

                <div class="columns is-mobile">
                    <div class="column is-6">
                        Profit Percentage: 
                    </div>
                    <div class="column has-text-right">
                        <VeeInput 
                            type="number" 
                            v-model="merchProfitPercentage"
                            name="merchProfitPercentage"
                            postfix="%"
                            :value="merchProfitPercentage"
                            :countryCurrencyCode="countryCurrencyCode"
                            :editable="trueValue"
                            @input="reCalculate"
                            />
                    </div>
                </div>


                <!-- <div>
                    <b-field label="Merchandise Cost" label-for="merchCost">
                        <VeeInput 
                            type="currency" 
                            v-model="merchCost"
                            name="merchCost"
                            :value="merchCost"
                            :countryCurrencyCode="countryCurrencyCode"
                            :editable="trueValue"
                            @input="reCalculate"
                            />
                    </b-field>
                </div>
                <div>
                    <b-field label="Profit Percentage" label-for="merchCost">
                        <VeeInput 
                            type="currency" 
                            v-model="merchProfitPercentage"
                            name="merchProfitPercentage"
                            :value="merchProfitPercentage"
                            :countryCurrencyCode="countryCurrencyCode"
                            :editable="trueValue"
                            @input="reCalculate"
                            />
                    </b-field>
                </div> -->
            </article>
            <article class="column box">
                <div class="has-background-white-ter has-text-centered">
                    MARKUP SALES PRICE
                </div>
                <br>
                <div class="columns is-mobile">
                    <div class="column">
                        Sales Price
                    </div>
                    <div class="column has-text-right">
                        {{ merchMarkupPrice | formatCurrency}}
                    </div>
                </div>
                <div class="columns is-mobile">
                    <div class="column">
                        Profit
                    </div>
                    <div class="column has-text-right">
                        {{ (merchMarkupPrice - merchCost) | formatCurrency }}
                    </div>
                </div>
                <div class="has-background-success-light has-text-success has-text-weight-bold is-size-7 has-text-centered" v-if="getSetting('profitCalculationMethod') == 'Markup'">
                    Current Store Settings
                </div>
            </article>
            <article class="column box inbox">
                <div class="has-background-white-ter has-text-centered has-text-weight-bold">
                    MARGIN SALES PRICE
                </div>
                <br>
                <div class="columns is-mobile">
                    <div class="column">
                        Sales Price
                    </div>
                    <div class="column has-text-right">
                        {{ merchMarginPrice | formatCurrency}}
                    </div>
                </div>
                <div class="columns is-mobile">
                    <div class="column">
                        Profit
                    </div>
                    <div class="column has-text-right">
                        {{ (merchMarginPrice - merchCost) | formatCurrency }}
                    </div>
                </div>
                <div class="has-background-success-light  has-text-success has-text-weight-bold is-size-7 has-text-centered" v-if="getSetting('profitCalculationMethod') == 'Margin'">
                    Current Store Setting
                </div>            
            </article>
        </div>
    </div>
</div>

</template>

<script>

import VeeInput from './VeeInput.vue'

export default {

    data() {
        return {
            showInventoryPriceCalculator: true,
            isPrinting: false,
            trueValue: true,
            merchCost: 0,
            merchProfitPercentage: 0,
            merchMarginPrice: 0,
            merchMarkupPrice: 0,
            countryCurrencyCode: this.getCountryCurrencyCode(),
            pageVersion: "1.0.1",
			pageName: "VeecliCalculators",
			introSteps: [
			{
				title: "Merchandise Retail Price Calculator",
				element: "#intro-calc-merch-head",
				intro: 'Calculate retail price for your merchandise according to your profit margin or markup.'
			},
			{
				title: 'Fule Retail Price Calculator',
				intro: 'Most accurate Fuel Retail/Break Even Price Calculator.  Update various Taxes and Fees and save.'
			},
			]
        }
    },

    components: {
        VeeInput
    },

    methods: {
        reCalculate() {
            this.merchMarginPrice = this.merchCost / (1 - (this.merchProfitPercentage / 100))
            this.merchMarkupPrice = this.merchCost * (1 + (this.merchProfitPercentage / 100))
        }
    }
}
</script>


<style>
.box {
    margin: 5px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    /* box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px; */
    /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
}

.custom-boxes-container .box:last-child { margin-bottom: 1.5rem;}
</style>